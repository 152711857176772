<template>
  <div class="flex items-center">
    <vs-avatar :src="this.author.photoURL" class="flex-shrink-0 mr-2" size="30px" @click="$router.push(url)" />
    <router-link :to="url" @click.stop.prevent class="text-inherit hover:text-primary">{{ this.author.full_name }}</router-link>
  </div>
</template>

<script>
export default {
  name: 'CellRendererAuthor',
  data() {
    return {
      author: {
        full_name: 'loading...',
        photoURL: 'https://portal-cdn.rapid.education/profile-pictures/default.jpeg'
      }
    }
  },
  computed: {
    url () {
      return `/a/users/${this.params.value}`
    },
    authorId() {
      return this.params.data.author;
    },
  },
  created() {

    if (this.params.value === '12345') {
      this.author.full_name = 'Rapid Portal'
      return;
    }


    this.$http.get(`users/${this.params.value}`)
      .then(response => {

        if (response.data.data) {
          this.author = response.data.data;
          this.author.full_name = `${this.author.first_name} ${this.author.last_name}`

          const pictureId = this.author.profile_picture || 'default';
          this.author.photoURL = `https://portal-cdn.rapid.education/profile-pictures/${pictureId}.jpeg`;
        }

      })
      .catch(exception => {

        let error = 'An unknown error occurred loading customer details';
        if (exception.response) {
          error = exception.response.data.error.description;
        }

        return this.$vs.notify({
          title: 'Failed to author',
          text: error,
          color: 'danger',
          position: 'top-right',
        });
      });

  }
};
</script>
